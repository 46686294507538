import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TwilioModalComponent } from '../twilio-modal/twilio-modal.component';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { QrCodeService } from '../services/qr-code.service';
import { Console } from 'console';
import { ApartmentService } from '../services/apartment.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-twilio-button',
  templateUrl: './twilio-button.component.html',
  styleUrls: ['./twilio-button.component.scss'],
})
export class TwilioButtonComponent implements OnInit {
  @Input() text: string = 'Text Me a Link';
  @Input() apartmentComplexId!: number;
  @Input() apartmentId!: number;
  shortUrl: string | null = null;
  qrCodeImage: string | null = null;
  logoPath: string = 'assets/images/qwikkit-logo.png';
  @Output() measurementFormDataLoaded = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;
  enableMeasurementFormUpload: boolean = false;
  @Output() isTokenInvalid = new EventEmitter<boolean>();

  @ViewChild('qrCanvas') qrCanvas!: ElementRef<HTMLCanvasElement>;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private qrCodeService: QrCodeService,
    private apartmentService: ApartmentService
  ) {}

  ngOnInit(): void {
    this.generateQrCode();
  }

  generateQrCode(): void {
    this.blockUI.start('Generating QR Code...');
    this.qrCodeService
      .generateQrCode(this.apartmentComplexId, this.apartmentId)
      .subscribe(
        (response) => {
          this.shortUrl = `${response.shortUrl}`;
          this.qrCodeImage = response.qrCodeBase64;
          setTimeout(() => {
            this.overlayLogo();
          }, 100);
          this.blockUI.stop();
        },
        (error) => {
          console.error('Error generating QR code:', error);
          this.blockUI.stop();
        }
      );
  }

  overlayLogo(): void {
    if (!this.qrCodeImage || !this.qrCanvas) return;

    const canvas = this.qrCanvas.nativeElement;
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    const qrImg = new Image();
    qrImg.src = this.qrCodeImage;
    qrImg.crossOrigin = 'Anonymous';
    qrImg.onload = () => {
      canvas.width = qrImg.width;
      canvas.height = qrImg.height;
      canvas.style.width = '70%';
      canvas.style.height = '70%';

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(qrImg, 0, 0, canvas.width, canvas.height);

      const logoImg = new Image();
      logoImg.src = this.logoPath;
      logoImg.crossOrigin = 'Anonymous';
      logoImg.onload = () => {
        const logoSize = canvas.width / 5;
        const x = (canvas.width - logoSize) / 2;
        const y = (canvas.height - logoSize) / 2;

        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');

        if (!tempCtx) return;

        tempCanvas.width = logoSize;
        tempCanvas.height = logoSize;

        tempCtx.fillStyle = 'white';
        tempCtx.fillRect(0, 0, logoSize, logoSize);

        tempCtx.drawImage(logoImg, 0, 0, logoSize, logoSize);

        ctx.drawImage(tempCanvas, x, y, logoSize, logoSize);
      };
    };
  }
  detectScanEvent(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['scanned'] === 'true') {
        this.router.navigate([], { queryParams: {} });
      }
    });
  }

  handleShortUrlClick(event: Event): void {
    if (!this.shortUrl) {
      console.error('Short URL is missing.');
      return;
    }
    this.qrCodeService.resolveShortUrl(this.shortUrl).subscribe(
      (response) => {
        if (response.isValidToken) {
          window.open(response.resolvedUrl, '_blank');
          this.isTokenInvalid.emit(false);
        }
      },
      (error) => {
        console.error('Error resolving short URL:', error);
        if (error.status === 401) {
          this.isTokenInvalid.emit(true);
        }
      }
    );
  }

  handleFreshClick(): void {
    this.generateQrCode();
    this.isTokenInvalid.emit(false);
  }

  handleMeasurementFormUpload(): void {
    this.blockUI.start('Getting Measurement Form Data...');
    this.apartmentService
      .getMeasurementFormData(this.apartmentComplexId, this.apartmentId)
      .subscribe(
        (response) => {
          this.measurementFormDataLoaded.emit(response);
          this.enableMeasurementFormUpload = true;
          this.blockUI.stop();
        },
        (error) => {
          console.error('Error getting measurement form data:', error);
          this.blockUI.stop();
          this.enableMeasurementFormUpload = false;
        }
      );
  }

  openTwilioModal(): void {
    this.dialog.open(TwilioModalComponent, {
      width: '400px',
      data: {},
    });
  }
}
