import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// Types
import { OrderSimilarData } from 'app/shared/types/order-shopping-cart';

// Services
import { ApartmentComplexService } from '../shared/services/apartment-complex.service';

// Rxjs
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-order-shopping-cart-similar-modal',
  templateUrl: './order-shopping-cart-similar-modal.component.html',
  styleUrls: [
    './order-shopping-cart-similar-modal.component.scss'
  ]
})
export class OrderShoppingCartSimilarModalComponent implements OnInit {

  // General
  public similarOrderForm: FormGroup;
  public apartmentNumberValid: boolean = true;
  public apartmentNumberMessage: string = '';

  // List
  private charNumbers: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'ArrowRight', 'ArrowLeft', 'v'];
  public charNotAllowed: string[] = [',', '"', '!', '"', '$', '%', '&', '\'', '*', ',', ':', ';', '<', '=', '>', '?', '@', '[', ']', '^', '_', '`', '{', '|', '}', '~', '\\'];

  constructor(
    private dialogRef: MatDialogRef<OrderShoppingCartSimilarModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: OrderSimilarData,
    private apartmentComplexService: ApartmentComplexService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.onInitSimilarForm()
  }

  private onInitSimilarForm() {
    this.similarOrderForm = this.formBuilder.group({
      apartmentNumber: new FormControl('', {
        validators: [Validators.required, this.onValidateSpecialCharsInApartmentNumber()],
        asyncValidators: [this.onValidateApartmentNumberAsync()],
        updateOn: 'blur'
      }),
      poNumber: ['', this.data.poNumberIsRequired && this.data.poNumberIsRequired ? Validators.required : null],
      apartmentType: [''],
      quantity: [1, [Validators.required, Validators.min(1)]],
      addOrderToShoppingCart: [false]
    });
  }

  private onValidateApartmentNumberAsync(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> | null => {
      return control.value !== '' ?
        this.apartmentComplexService.validApartment(control.value, this.data.shoppingCartItem.apartmentComplexId, 0)
          .pipe(
            map((result: any) => {
              if (result && !result.isValid) {
                this.apartmentNumberMessage = result.message;
                return ({ apartmentExist: true });
              } else {
                return null;
              }
            }),
            catchError(() => of(null))) :
        of(null);
    }
  }

  private onValidateSpecialCharsInApartmentNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      for (const char of this.charNotAllowed) {
        if (control.value.includes(char)) {
          return { specialChar: true };
        }
      }
    }
  }

  onValidateControl(controlName: string, validation: string): boolean {
    let selectControl = this.similarOrderForm.get(controlName);
    if (selectControl) {
      return selectControl.hasError(validation);
    }
  }

  onKeyDownOnlyNumbers(event: KeyboardEvent): boolean {
    if (!this.charNumbers.includes(event.key)) {
      event.preventDefault();
      return false;
    }
  }

  onAllowSaveForm(): boolean {
    return this.similarOrderForm.touched && this.similarOrderForm.valid;
  }

  onCreateSimilarOrder() {
    this.data.quantity = this.similarOrderForm.get('quantity').value !== null ? this.similarOrderForm.get('quantity').value : 1;
    this.data.addOrderToShoppingCart = this.similarOrderForm.get('addOrderToShoppingCart').value;
    this.data.apartment = {
      id: this.data.shoppingCartItem.apartmentId,
      apartmentComplexID: this.data.shoppingCartItem.apartmentComplexId,
      apartmentNumber: this.similarOrderForm.get('apartmentNumber').value,
      apartmentType: this.similarOrderForm.get('apartmentType').value,
      customerPONumber: this.similarOrderForm.get('poNumber').value,
      noChargeReasonCodeId: this.data.noChargeReasonCodeId && this.data.noChargeReasonCodeId > 0 ? this.data.noChargeReasonCodeId : null
    };
    this.dialogRef.close({ ...this.data, state: 'success' });
  }

  onCloseModal() {
    this.dialogRef.close({ ...this.data, state: 'cancel' });
  }

}
