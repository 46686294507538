import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.scss']
})
export class LoginFooterComponent implements OnInit {
  public slogan = 'OPEN THE DOOR TO EASY';
  public phone = '844-4-THE-KIT (844-484-3548)';

  constructor() { }

  ngOnInit() {
  }

}
