import { Injectable, OnDestroy } from '@angular/core';
import { SignalrService } from './signalr.service';
import { Subscription } from 'rxjs/Subscription';
import { catchError, filter, tap } from 'rxjs/operators';
import { OrderReviewService } from './order-review.service';
import { PackagePlanningService } from './package-planning.service';
import { ShoppingCartItem } from '../types/shopping-cart-item';
import { PackagePlanStatusMessage, Status } from '../types/package-plan-status-message';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';

const RECEIVED_MESSAGE_TIMEOUT = 10000;
const COMPLETED_MESSAGE_TIMEOUT = 14000;
const SIGNALR_ERROR_MESSAGE = 'The package planning notifications may not be available.';
const TOAST_POSITION = 'toast-bottom-right';

@Injectable({
  providedIn: 'root'
})
export class AddToCartService implements OnDestroy {

  private signalrError = false;
  private errorSubscription: Subscription;
  private startingSubscription: Subscription;

  constructor(
    private signalrService: SignalrService,
    private packagePlanningService: PackagePlanningService,
    private orderReviewService: OrderReviewService,
    private toastr: ToastrService
  ) {

    this.errorSubscription = this.signalrService.error$.subscribe(
      (error: any) => {
        this.signalrError = true;
        console.warn(error);
      },
      (error: any) => {
        this.signalrError = true;
        console.error('errors$ error', error);
      }
    );

    // Wire up a handler for the starting$ observable to log the
    //  success/fail result
    //
    this.startingSubscription = this.signalrService.starting$.subscribe(
      () => {
        console.log('Signalr service has been started');
      },
      () => {
        console.warn('Signalr service failed to start!');
      }
    );

    try {
      if (!this.signalrService.isConnected()) {
        console.log('Add To Cart Action: signalrService.start');
        this.signalrService.connect();
      }
    } catch (err) {
      this.signalrError = true;
      console.error('Error starting the signalr connection to a hub.', err);
    }
  }

  signalrConnectionIsActive() {
    console.log('Action: signalrConnectionIsActive');
    return !this.signalrError && this.signalrService.isConnected();
  }

  async canExecutePackagePlanProcess(apartmentId: number) {
    console.log('Action: canExecutePackagePlanProcess');
    return await this.orderReviewService.validateOrder(apartmentId).toPromise();
  }

  async onHandlePackagePlanExecutionAsync(apartmentId: number, callback?: () => void) {
    callback = callback || (() => console.log('Executing default addToCart callback.'));
    try {
      if (!this.signalrConnectionIsActive()) {
        this.toastr.warning(SIGNALR_ERROR_MESSAGE, '', { positionClass: TOAST_POSITION });
      }
      if (await this.canExecutePackagePlanProcess(apartmentId)) {
        let receivedMsgTimeoutId: any;
        let completedMsgTimeoutId: any;
        let statusMessage: PackagePlanStatusMessage;
        const item: ShoppingCartItem = {
          apartmentId: apartmentId
        };
        console.log('Action: packagePlanningService.process');
        const packagePlanningSubscription = this.packagePlanningService.process(item)
          .subscribe(_ => console.log('Successful package planning process request.'),
            err => console.error('Error with the package planning process request:', err));
        const signalrServiceSubscription = this.signalrService.statusMessage
          .pipe(filter((msg: PackagePlanStatusMessage) => item.apartmentId === msg.apartmentId))
          .subscribe(async (msg: PackagePlanStatusMessage) => {
            statusMessage = msg;
            console.log('Status message:', msg);
            if (msg.currentStatus === Status.Completed || msg.currentStatus === Status.Error) {
              console.log('Package planning process finished. Current status:', msg.currentStatus);
              return _callback();
            }
          });
        completedMsgTimeoutId = setTimeout(() => {
          if (statusMessage && statusMessage.currentStatus !== Status.Completed) {
            console.log('Package planning completed message timeout.');
            return _callback();
          }
        }, COMPLETED_MESSAGE_TIMEOUT);

        receivedMsgTimeoutId = setTimeout(() => {
          if (statusMessage == null) {
            console.log('Package planning received message timeout.');
            return _callback();
          }
        }, RECEIVED_MESSAGE_TIMEOUT);

        const _callback = () => {
          clearTimeout(receivedMsgTimeoutId);
          clearTimeout(completedMsgTimeoutId);
          signalrServiceSubscription.unsubscribe();
          packagePlanningSubscription.unsubscribe();
          return callback && callback();
        };
      } else {
        return callback && callback();
      }
    } catch (e) {
      console.error('Error in addToCart action:', e);
      return callback && callback();
    }
  }

  onHandlePackagePlanExecutionByObserver(apartmentId: number): Observable<void> {
    return new Observable<void>(observer => {
      try {
        if (!this.signalrConnectionIsActive()) {
          this.toastr.warning(SIGNALR_ERROR_MESSAGE, '', { positionClass: TOAST_POSITION });
        }

        this.canExecutePackagePlanProcess(apartmentId).then(canExecute => {
          if (canExecute) {
            let receivedMsgTimeoutId: any;
            let completedMsgTimeoutId: any;
            let statusMessage: PackagePlanStatusMessage;
            const item: ShoppingCartItem = {
              apartmentId: apartmentId
            };

            console.log('Action: packagePlanningService.process');
            const packagePlanningSubscription = this.packagePlanningService.process(item)
              .pipe(
                tap(_ => console.log('Successful package planning process request.')),
                catchError(err => {
                  console.error('Error with the package planning process request:', err);
                  observer.error(err);
                  return of();
                })
              ).subscribe();

            const signalrServiceSubscription = this.signalrService.statusMessage
              .pipe(
                filter((msg: PackagePlanStatusMessage) => item.apartmentId === msg.apartmentId),
                tap(async (msg: PackagePlanStatusMessage) => {
                  statusMessage = msg;
                  console.log('Status message:', msg);
                  if (msg.currentStatus === Status.Completed || msg.currentStatus === Status.Error) {
                    console.log('Package planning process finished. Current status:', msg.currentStatus);
                    completeProcess();
                  }
                })
              ).subscribe();

            completedMsgTimeoutId = setTimeout(() => {
              if (statusMessage && statusMessage.currentStatus !== Status.Completed) {
                console.log('Package planning completed message timeout.');
                completeProcess();
              }
            }, COMPLETED_MESSAGE_TIMEOUT);

            receivedMsgTimeoutId = setTimeout(() => {
              if (statusMessage == null) {
                console.log('Package planning received message timeout.');
                completeProcess();
              }
            }, RECEIVED_MESSAGE_TIMEOUT);

            const completeProcess = () => {
              clearTimeout(receivedMsgTimeoutId);
              clearTimeout(completedMsgTimeoutId);
              signalrServiceSubscription.unsubscribe();
              packagePlanningSubscription.unsubscribe();
              observer.next();
              observer.complete();
            };
          } else {
            observer.next();
            observer.complete();
          }
        }).catch(err => {
          console.error('Error in addToCart action:', err);
          observer.error(err);
        });
      } catch (e) {
        console.error('Error in addToCart action:', e);
        observer.error(e);
      }
    });
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
    this.startingSubscription.unsubscribe();
  }
}
