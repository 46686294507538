export class Apartment {
  constructor(
    public id: number,
    public apartmentComplexID: number,
    public apartmentComplexName: string,
    public apartmentNumber: string,
    public apartmentDescription: string,
    public notes: string,
    public apartmentTotal: number,
    public createdDate: string,
    public modifiedDate: string,
    public customerPONumber: string,
    public orderNumber: string,
    public orderDate: string,
    public orderStatus: string,
    public isArchived: boolean,
    public status: number,
    public apartmentType: string,
    public isAbleEdit:boolean,
    public partsOrder?: boolean,
    public orderStatusID?: number,
    public isQuote?: boolean,
    public isQuoteAudit?: boolean,
    public shoppingCartUserId?: string,
    public submitUserName?: string,
    //NoChargeOrder
    public applyNoCharge?: boolean,
    public noChargeReasonCodeId?: number,
    // New Fields
    public apartmentComplexId?: number,
    public carrier?: string,
    public deliveryDate?: string,
    public isPalletized?: boolean,
    public isSelected?: boolean,
    public modifiedUser?: string,
    public plannedShipmentDate?: string,
    public quoteExpirationDate?: string,
    public QuoteExpirationRemainingDay?: number,
    public regionalManagerId?: number,
    public regionalManagerName?: string,
    public territoryManagerId?: number,
    public territoryManagerName?: string,
    public totalDoors?: number,
    public totalDrawers?: number,
    public trackingNo?: string,
    public trackingNumbers?: string,
    public userName?: string,
    public vPManagerId?: number,
    public vPManagerName?: string
  ) {}
}

export class pullItemQuantity {
  constructor(
    public itemNumber: string,
    public quantity: number,
  )
  {}
}
