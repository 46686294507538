import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { TwilioModalComponent } from '../twilio-modal/twilio-modal.component';
import { TwilioButtonComponent } from '../twilio-button/twilio-button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { QrModalComponent } from '../qr-modal/qr-modal.component';
@NgModule({
  declarations: [
    TwilioButtonComponent, 
    TwilioModalComponent, 
    QrModalComponent 
  ],
  imports: [
    CommonModule,
    MatDialogModule, 
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    TwilioButtonComponent 
  ],
  entryComponents: [
    TwilioModalComponent,
    QrModalComponent
  ]
})
export class TwilioModule {}