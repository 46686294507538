export interface Active {
  active: string;
  search: string;
  setNew: string;
  quote: string;
  noCharge: string;
}

export interface MenuList {
  editNotes?: string;
  editLocation?: string;
  assignOrderToMe?: string;
  orderNew?: string;
  orderSimilar?: string;
  orderSimilarNoCharge?: string;
  addToCart?: string;
  addToQuote?: string;
  archiveOrder?: string;
  addPoToOrder?: string;
  print?: string;
  modify?: string,
  cancel?: string;
  invoice?: string
  shippingInfo?: string;
  package?: string;
  getPackagingList?: string;
  downloadInvoice?: string;
}

export interface MenuListItem {
  text: string;
  value: string;
}

export enum SectionType {
  dashboard,
  apartments
}