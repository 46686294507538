import { Injectable, OnDestroy } from '@angular/core';
import { SignalrService } from './signalr.service';
import { Subscription } from 'rxjs/Subscription';
import { catchError, filter, tap } from 'rxjs/operators';
import { OrderReviewService } from './order-review.service';
import { PackagePlanningService } from './package-planning.service';
import { ShoppingCartItem } from '../types/shopping-cart-item';
import { PackagePlanStatusMessage, Status } from '../types/package-plan-status-message';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';

const RECEIVED_MESSAGE_TIMEOUT = 10000;
const COMPLETED_MESSAGE_TIMEOUT = 14000;
const SIGNAL_ERROR_MESSAGE = 'The package planning notifications may not be available.';
const TOAST_POSITION = 'toast-bottom-right';

@Injectable({
  providedIn: 'root'
})
export class AddToCartService implements OnDestroy {

  private signalError = false;
  private errorSubscription: Subscription;
  private startingSubscription: Subscription;

  constructor(
    private signalService: SignalrService,
    private packagePlanningService: PackagePlanningService,
    private orderReviewService: OrderReviewService,
    private toastNotification: ToastrService
  ) {

    this.errorSubscription = this.signalService.error$.subscribe(
      (error: any) => {
        this.signalError = true;
        console.warn(error);
      },
      (error: any) => {
        this.signalError = true;
        console.error('errors$ error', error);
      }
    );

    // Wire up a handler for the starting$ observable to log the
    //  success/fail result
    //
    this.startingSubscription = this.signalService.starting$.subscribe(
      () => {
        console.log('Signal service has been started');
      },
      () => {
        console.warn('Signal service failed to start!');
      }
    );

    try {
      if (!this.signalService.isConnected()) {
        console.log('Add To Cart Action: signalService.start');
        this.signalService.connect();
      }
    } catch (err) {
      this.signalError = true;
      console.error('Error starting the signal connection to a hub.', err);
    }
  }

  signalConnectionIsActive() {
    console.log('Action: signalConnectionIsActive');
    return !this.signalError && this.signalService.isConnected();
  }

  async canExecutePackagePlanProcess(apartmentId: number) {
    console.log('Action: canExecutePackagePlanProcess');
    return await this.orderReviewService.validateOrder(apartmentId).toPromise();
  }

  async onHandlePackagePlanExecutionAsync(apartmentId: number, callback?: () => void) {
    const defaultCallback = () => console.log('Executing default addToCart callback.');
    const cb = callback || defaultCallback;
  
    if (!this.signalConnectionIsActive()) {
      this.toastNotification.warning(SIGNAL_ERROR_MESSAGE, '', { positionClass: TOAST_POSITION });
      return cb();
    }
  
    try {
      if (!(await this.canExecutePackagePlanProcess(apartmentId))) {
        return cb();
      }
  
      const item: ShoppingCartItem = { apartmentId };
      console.log('Action: packagePlanningService.process');
  
      const packagePlanningSubscription = this.packagePlanningService.process(item)
        .subscribe(
          () => console.log('Successful package planning process request.'),
          err => console.error('Error with the package planning process request:', err)
        );
  
      let statusMessage: PackagePlanStatusMessage | null = null;
  
      const signalServiceSubscription = this.signalService.statusMessage
        .pipe(filter((msg: PackagePlanStatusMessage) => item.apartmentId === msg.apartmentId))
        .subscribe(async (msg: PackagePlanStatusMessage) => {
          statusMessage = msg;
          console.log('Status message:', msg);
  
          if (msg.currentStatus === Status.Completed || msg.currentStatus === Status.Error) {
            console.log('Package planning process finished. Current status:', msg.currentStatus);
            return completeProcess();
          }
        });
  
      const receivedMsgTimeoutId = setTimeout(() => {
        if (!statusMessage) {
          console.log('Package planning received message timeout.');
          completeProcess();
        }
      }, RECEIVED_MESSAGE_TIMEOUT);
  
      const completedMsgTimeoutId = setTimeout(() => {
        if (statusMessage.currentStatus !== Status.Completed) {
          console.log('Package planning completed message timeout.');
          completeProcess();
        }
      }, COMPLETED_MESSAGE_TIMEOUT);
  
      const completeProcess = () => {
        clearTimeout(receivedMsgTimeoutId);
        clearTimeout(completedMsgTimeoutId);
        signalServiceSubscription.unsubscribe();
        packagePlanningSubscription.unsubscribe();
        cb();
      };
    } catch (e) {
      console.error('Error in addToCart action:', e);
      return cb();
    }
  }

  async onHandlePackagePlanExecutionBetaAsync(apartmentId: number, callback?: () => void) {
    const defaultCallback = () => console.log('Executing default addToCart callback.');
    const cb = callback || defaultCallback;
  
    try {
      if (!(await this.canExecutePackagePlanProcess(apartmentId))) {
        return cb();
      }
  
      const item: ShoppingCartItem = { apartmentId };
      console.log('Action: packagePlanningService.process');
  
      const packagePlanningSubscription = this.packagePlanningService.process(item)
        .subscribe(
          () => { return completeProcess(); },
          err => console.error('Error with the package planning process request:', err)
        );

      const completeProcess = () => {
        packagePlanningSubscription.unsubscribe();
        cb();
      };
    } catch (e) {
      console.error('Error in addToCart action:', e);
      return cb();
    }
  }
  

  onHandlePackagePlanExecutionByObserver(apartmentId: number): Observable<void> {
    return new Observable<void>(observer => {
      try {
        if (!this.signalConnectionIsActive()) {
          this.toastNotification.warning(SIGNAL_ERROR_MESSAGE, '', { positionClass: TOAST_POSITION });
        }

        this.canExecutePackagePlanProcess(apartmentId).then(canExecute => {
          if (canExecute) {
            let receivedMsgTimeoutId: any;
            let completedMsgTimeoutId: any;
            let statusMessage: PackagePlanStatusMessage;
            const item: ShoppingCartItem = {
              apartmentId: apartmentId
            };

            console.log('Action: packagePlanningService.process');
            const packagePlanningSubscription = this.packagePlanningService.process(item)
              .pipe(
                tap(_ => console.log('Successful package planning process request.')),
                catchError(err => {
                  console.error('Error with the package planning process request:', err);
                  observer.error(err);
                  return of();
                })
              ).subscribe();

            const signalServiceSubscription = this.signalService.statusMessage
              .pipe(
                filter((msg: PackagePlanStatusMessage) => item.apartmentId === msg.apartmentId),
                tap(async (msg: PackagePlanStatusMessage) => {
                  statusMessage = msg;
                  console.log('Status message:', msg);
                  if (msg.currentStatus === Status.Completed || msg.currentStatus === Status.Error) {
                    console.log('Package planning process finished. Current status:', msg.currentStatus);
                    completeProcess();
                  }
                })
              ).subscribe();

            completedMsgTimeoutId = setTimeout(() => {
              if (statusMessage && statusMessage.currentStatus !== Status.Completed) {
                console.log('Package planning completed message timeout.');
                completeProcess();
              }
            }, COMPLETED_MESSAGE_TIMEOUT);

            receivedMsgTimeoutId = setTimeout(() => {
              if (statusMessage == null) {
                console.log('Package planning received message timeout.');
                completeProcess();
              }
            }, RECEIVED_MESSAGE_TIMEOUT);

            const completeProcess = () => {
              clearTimeout(receivedMsgTimeoutId);
              clearTimeout(completedMsgTimeoutId);
              signalServiceSubscription.unsubscribe();
              packagePlanningSubscription.unsubscribe();
              observer.next();
              observer.complete();
            };
          } else {
            observer.next();
            observer.complete();
          }
        }).catch(err => {
          console.error('Error in addToCart action:', err);
          observer.error(err);
        });
      } catch (e) {
        console.error('Error in addToCart action:', e);
        observer.error(e);
      }
    });
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
    this.startingSubscription.unsubscribe();
  }
}
