import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { Token } from '../types/token';
import { LoginService } from './login.service';
import { ApartmentComplex } from '../types/apartment-complex';
import { SectionType } from '../types/dashboard';

@Injectable()
export class LandingApartmentComplexService {
  public isDetails = new BehaviorSubject(false);

  public dashBoardText: any = {
    title: '',
    titleComplement: '',
    active: 'ACTIVE',
    archived: 'ARCHIVED',
    costumer_number: ''
  };
  public landing: boolean;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  getLandingApartmentComplex(index: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/apartments/${index}`, {headers: headers});
  }

  saveLandingApartmentNotes(index: number, note: string): Observable<any> {
     const headers = new HttpHeaders();
     headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/apartments/${index}/notes?aptId=${index}&notes=${note}`, {}, {headers: headers});
  }

  setUI(sectionType: SectionType, apartmentComplex?: ApartmentComplex) {
    let company = `${this.loginService.getToken()['companyname']} `;
    switch (sectionType) {
      case SectionType.dashboard:
        this.dashBoardText.title = company
        this.dashBoardText.titleComplement = 'Update Account Information';
        this.dashBoardText.costumer_number = this.loginService.getToken()['customernumber'];
        this.landing = false;
        break;
      case SectionType.apartments:
        this.dashBoardText.companyName = company;
        this.dashBoardText.title = apartmentComplex.name;
        this.dashBoardText.titleComplement = `${apartmentComplex.city}, ${apartmentComplex.state}`;
        this.landing = true;
        break;
    }
  }
}
