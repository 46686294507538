import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { LoginService } from './services/login.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { environment } from '../../environments/environment';
import { JsonWebToken } from './types/token';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: LoginService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes(environment.schedulingAPI)) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
      return next.handle(request);
    }
    else if (request.url.includes(environment.apiQwikkitOrdering)) {
      const tokenStorage = localStorage.getItem('tokenQwikkitOrdering');
      if (tokenStorage) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${(<JsonWebToken>JSON.parse(tokenStorage)).value}`
          }
        });
      }
      return next.handle(request);
    }
    else {
      request = request.clone({
        setHeaders: {
          Authorization: `${this.auth.getToken().token_type} ${this.auth.getToken().access_token}`
        }
      });

      return next.handle(request).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // redirect to the login route
            // or show a modal
            this.auth.logOut();
          }
        }
      });
    }
  }
}
