import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { ServerStatusService } from '../shared/services/server-status.service';
import { CreateAccountService } from '../shared/services/create-account.service';
import { LoginValues } from '../shared/mock/login-values';
import { CustomerAccess, Login } from '../shared/types/login';
import { Credentials } from '../shared/types/credentials';
import { ModalComponent } from '../modal/modal.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient } from '@angular/common/http';
import { CustomerLogin } from '../shared/types/customer-login'
import { SearchService } from 'app/shared/services/search.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @BlockUI() blockUi: NgBlockUI;
  @ViewChild('forgotPass') public forgotPass: ModalComponent;
  @ViewChild('multipleAccounts') public multipleAccounts: ModalComponent;
  @ViewChild('childModal') public childModal: ModalComponent;
  @ViewChild('spinner') public spinner: SpinnerComponent;

  public ipAddress: any;
  public LoginTextValues: Login = LoginValues;
  public credentials: Credentials = {
    username: '',
    grant_type: 'password',
    password: ''
  };
  public loginForm: FormGroup;
  public messageLedge: any;
  public tableLedge: any;
  public radiusActive = true;
  public emptyUser = false;
  public emptyPass = false;
  public loginError = 'You must add a password.';
  public emailRequired = `*An Email is required.`;
  public emailError = '*Invalid email.';
  public userEmail = '';
  public email_sent: boolean;
  public sendEmail = new FormControl('', [Validators.required]);
  public multipleAccountForm = new FormControl('', [Validators.required]);
  public deviceInfo: any;
  public buttonStatus = true;
  public customerLedge: any;
  public total = 0;
  public websiteAnnouncement: string;
  public textMessage = '';
  public materialIconName = '';

  public accounts: CustomerAccess[] = [];
  public accountsClone: CustomerAccess[] = [];
  public userAccessList: CustomerAccess[] = [];
  public customerID = 0;
  public scrolledToEnd: boolean = false;
  public token: any;
  public customer = {} as CustomerLogin;
  public searchAccount: string = '';

  constructor(
    private loginService: LoginService,
    private serverStatusService: ServerStatusService,
    private createAccountService: CreateAccountService,
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private searchService: SearchService) {
    this.loginForm = formBuilder.group({
      'username': [null, Validators.required],
      'password': [null, Validators.required]
    });
  }

  ngOnInit() {
    if (this.epicFunction()) {
      this.getIPAddress();
      this.onHandleLoginSubscriptions();
      this.buttonStatus = true;
    } else {
      this.buttonStatus = false;
      this.blockUi.stop();
      this.emptyPass = true;
      this.loginError = this.serverStatusService.status(402, 'Your browser is not supported by the site.');
      this.loginForm.reset();
    }
  }

  onHandleLoginSubscriptions() {
    this.loginService.authenticate
      .subscribe((value: boolean) => value ? this.router.navigateByUrl('/dashboard', { replaceUrl: true }) : false);
    this.createAccountService.getWebsiteAnnouncements()
      .subscribe((announcement: string) => this.websiteAnnouncement = announcement);
    this.loginService.accessCustomerList
      .subscribe((value: CustomerAccess[]) => this.userAccessList = value);
  }

  ngOnDestroy() { }

  async signIn() {
    if (!this.credentials['username']) {
      this.emptyUser = true;
    }
    if (!this.credentials['password']) {
      this.emptyPass = true;
    }
    if (!this.emptyUser && !this.emptyPass) {
      this.blockUi.start('Loading...');
      try {
        this.token = await this.loginService.signIn(this.credentials).toPromise();
        this.customer.UserId = this.token.userId;
        this.customer.CustomerNumber = this.token.customernumber;
        if (this.userAccessList.length > 0) {
          this.blockUi.stop();
          this.accounts = this.userAccessList;
          this.accountsClone = this.accounts;
          this.modalMultipleAccounts();
        }
        else {
          this.router.navigate(['/dashboard']);
        }
        this.RecordAuditSiteLogin('');
      } catch (err) {
        this.blockUi.stop();
        this.emptyPass = true;
        this.loginError = this.serverStatusService.status(err.status, err.error);
        this.loginForm.reset();
      }
    }
    if (this.userAccessList.length === 0) {
      await this.loginService.customerLogin(this.customer);
    }
  }


  cleanLogin(event) {
    const value = event.target.value;
    if (value.length > 0 && event.keyCode !== 13) {
      this.emptyUser = false;
      this.emptyPass = false;
      this.loginError = 'You must add a password.';
    }
  }

  continue() {
    this.router.navigate(['account']);
  }

  modalPass(): void {
    this.forgotPass.showChildModal();
  }

  async sendMail(email: string) {
    this.blockUi.start('Loading...');
    try {
      var response = await this.createAccountService.forgotPassword(email).toPromise();
      this.email_sent = true;
      this.textMessage = response.isFound ? 'Email sent!' : 'Email not found!';
      this.materialIconName = response.isFound ? 'check' : 'clear';
      this.blockUi.stop();
    } catch (err) {
      this.blockUi.stop();
      throw new Error(err);
    }
  }

  resetView(event: boolean) {
    this.email_sent = false;
    this.userEmail = '';
    this.sendEmail.markAsPristine();
  }

  async setCustomerID(customerID: number) {
    this.customerID = customerID;
    if (this.customerID && this.customerID > 0) {
      this.loginService.setAccount(this.customerID).then(() => {
        this.loginService.logInQwikkitOrdering(this.credentials);
      })
      this.multipleAccounts.hideChildModal();
      this.customer.CustomerId = this.customerID;
      this.loginService.customerLogin(this.customer);
    }
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const browser = this.deviceInfo.browser;
    if (browser === 'Chrome' || browser === 'MS-Edge' || browser === 'Firefox' || browser === 'MS-Edge-Chromium' || browser === "Safari") {
      return true;
    } else {
      return false;
    }
  }

  CalculateLeadgeTotal() {
    if (this.customerLedge !== undefined) {
      for (let i = 0; i < this.customerLedge.length; i++) {
        this.total = this.total + this.customerLedge[i].Remaining_Amt_LCY;
      }
    }
  }

  modalMultipleAccounts(): void {
    this.multipleAccounts.showChildModal();
  }

  openNewTabNewAccount() {
    window.open('https://qwikkit.com/contact-us/', '_blank');
  }

  openTabHomeOwner() {
    window.open('https://bit.ly/3stMusC', '_blank');
  }

  RecordAuditSiteLogin(ipAddress: string) {
    this.createAccountService.recordSIteAudit(ipAddress).toPromise();
  }

  async getIPAddress() {
    this.createAccountService.recordSIteAuditVisitor().toPromise();
  }

  onSearchAccounts(searchValue: string) {
    this.accounts = this.accountsClone;
    this.accounts = this.searchService.search(this.accounts, searchValue, ['customerNumber']);
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrolledToEnd = true;
    }
  }
}
