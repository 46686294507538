import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AccountService } from 'app/shared/services/account.service';
import { UserTermsAndConditionsStatus } from 'app/shared/types/user-info';


@Component({
  selector: 'app-term-conditions-modal',
  templateUrl: './term-conditions-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./term-conditions-modal.component.scss']
})
export class TermConditionsModalComponent implements OnInit, AfterViewInit {
  termConditionsState: UserTermsAndConditionsStatus;
  pathDocument: string;
  completeRead: boolean = false;
  acceptTerms: boolean = false;

  constructor(
    public scroll: ScrollDispatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<TermConditionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.termConditionsState = this.data.termConditionsState;
    this.pathDocument = `../../assets/terms-and-conditions/${ this.termConditionsState.versionNumber }.md`;
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.scroll.scrolled().subscribe((data: CdkScrollable) => {
      const { scrollTop, scrollHeight, clientHeight } = data.getElementRef().nativeElement;
      this.completeRead = ((scrollTop / (scrollHeight - clientHeight ) ) * 100) < 99;
      this.changeDetectorRef.detectChanges();
    });
  }

  async onSaveConfirmTerms() {
    if (this.acceptTerms) {
      await this.accountService.postAcceptTermsAndConditions({
        termsAndConditionsVersionId: this.termConditionsState.termsAndConditionsVersionId,
        userAccepted: this.acceptTerms})
        .toPromise()
        .then((response) => {
          this.termConditionsState.userAccepted = response ? response : false;
          this.dialogRef.close(this.termConditionsState); })
        .catch((err) => {
          console.error(`Error in accept terms: ${ err }`);
          this.dialogRef.close(this.termConditionsState);
        });
    }
  }
}
