import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import { Token } from '../types/token';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { NewAddress } from '../types/shipping-address';
import { DeliveryEstimationRequest, ApartmentUpdateRequest } from '../types/shopping-cart';
import { CreditCard } from '../types/credit-card';
import { RequestOptions } from '@angular/http';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { ShippingCost } from '../types/ratesTransitTimes';

@Injectable()
export class ShoppingCartService {
  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  private showFile(blob, file) {
    const newBlob = new Blob([blob], { type: 'application/pdf' });

    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(newBlob, `${file}.pdf`);
      return;
    }
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob, `${file}.pdf`);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.target = '_blank';
    link.download = `${file}.pdf`;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(data);
    }, 1000);
    return Observable.of(data);
  }

  downloadFile(url: string, shoppingCartGroup: number, name: string, isQuote: string) {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    let file = url.includes('quote') ? 'quote-' : 'acknowledgement-';
    file += name;
    if (url.includes('acknowledgement')) {
      searchParams['shoppingCartGroup'] = `${shoppingCartGroup}`;
    } else {
      searchParams['isQuote'] = `${isQuote}`;
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    enum ResponseContentType {
      Blob = 'blob'
    }
    const requestOptions = { headers, params: searchParams, responseType: ResponseContentType.Blob };
    return this.http.get(`${environment.api}${url}`, requestOptions)
      .map((blob) => this.showFile(blob, file))
      .toPromise();
  }

  printPDF(url: string, shoppingCart: any, name: string) {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    let file = 'acknowledgement-';
    file += name;
    searchParams['shoppingCartGroup'] = `${shoppingCart.shoppingGroup}`;
    searchParams['userId'] = `${shoppingCart.userId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    enum ResponseContentType {
      Blob = 'blob'
    }
    const requestOptions = { headers, params: searchParams, responseType: ResponseContentType.Blob };
    return this.http.get(`${environment.api}${url}`, requestOptions)
      .map((blob) => this.showFile(blob, file))
      .toPromise();
    // .map((res: any) => {
    //   console.log(ress);
    //   return res;
    // })
  }

  printAcknowledgment(url: string,  name: string, apartmentId: string ) {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    let file = 'acknowledgement-';
    file += name;
    searchParams['apartmentId'] = `${apartmentId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    enum ResponseContentType {
      Blob = 'blob'
    }
    const requestOptions = { headers, params: searchParams, responseType: ResponseContentType.Blob };
    return this.http.get(`${environment.api}${url}`, requestOptions)
      .map((blob) => this.showFile(blob, file))
      .toPromise();

  }

  printPackaging(orderNumber: string, apartmentNumber: string) {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    let file = 'packing list-';
    searchParams['orderNumber'] = `${orderNumber}`;
    searchParams['apartmentNumber'] = `${apartmentNumber}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    enum ResponseContentType {
      Blob = 'blob'
    }
    const requestOptions = { headers, params: searchParams, responseType: ResponseContentType.Blob };
    return this.http.get(`${environment.api}api/orders/print/packageListAll`, requestOptions)
      .map((blob) => this.showFile(blob, file))
      .toPromise();
  }

  getInvoice(PSINo: string) {
    let searchParams: any = new Object();
    searchParams['PSINo'] = PSINo;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    enum ResponseContentType {
      Blob = 'blob'
    }
    const requestOptions = { headers, params: searchParams, responseType: ResponseContentType.Blob };
    let file = 'Invoice_' + PSINo;
    return this.http.get(`${environment.api}api/account/getinvoice`, requestOptions)
      .map((blob) => this.showFile(blob, file))
      .catch(() => of(''))
      .toPromise();
  }

  getPSI(SONumber: string): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<string>(`${environment.api}api/account/getPSI`, {headers, params: {'SO': SONumber}, responseType:'json'});
  }

  sendAcknowledgement(shoppingCartGroup: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http
      .post(`${environment.api}api/orders/sendacknowledgment?shoppingCartGroup=${shoppingCartGroup}`, {}, { headers });
  }

  getPalletForm(): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.get(`${environment.api}api/orders/isorderpalletized`, requestOptions);
  }

  sendEmailToCustomerService(shoppingGroup: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let searchParams: any = new Object();
    searchParams['shoppingGroup'] = shoppingGroup;
    return this.http.post(`${environment.api}api/orders/email-customer-sending`, {}, { headers, params: searchParams });
  }

  markAsOvernight(isOvernight: boolean): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/orders/mark-as-overnight?isOvernight=${isOvernight}`, {}, { headers });
  }

  markAsPalletized(isPalletized: boolean, addressId: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/orders/markAsPalletized?isPalletized=${isPalletized}&addressId=${addressId}`, {}, {headers});
  }

  validatePoNumber(apartmentID: number): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentID'] = apartmentID.toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers, params: searchParams };
    return this.http.get(`${environment.api}api/orders/validateponumber`, requestOptions);
  }

  validateCreditLimit(): Observable<any> {
    // tslint:disable-next-line:prefer-const
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.get(`${environment.api}api/orders/validatecreditlimit`, requestOptions);
  }

  displayShippingAddress(apartmentId: number, isQuote: string): Observable<any> {
    const searchParams: any = new Object();
    searchParams['apartmentId'] = apartmentId;
    searchParams['isQuote'] = isQuote;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers, params: searchParams };
    return this.http.get(`${environment.api}api/orders/showshippingaddress`, requestOptions);
  }

  getAllowNumberPartsToPalletize(): Observable<number> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.get<number>(`${environment.api}api/orders/partstopalletize`, requestOptions);
  }

  checkCanPaletize() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.get(`${environment.api}api/orders/canpalletize`, requestOptions);
  }

  partstopalletize() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.get(`${environment.api}api/orders/partstopalletize`, requestOptions);
  }

  getShoppingCart(isQuote: string, expeditedItemNumbers: string = ''): Observable<any> {
    const token = this.loginService.getToken();
    const searchParams: any = new Object();
    searchParams['isQuote'] = isQuote;
    searchParams['customer'] = token.customernumber;

    // Add expeditedItemNumbers to searchParams only if it's not empty
    if (expeditedItemNumbers.length > 0) {
        searchParams['expeditedItemNumbers'] = expeditedItemNumbers;
    }

    const headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });
    const requestOptions = { headers, params: searchParams };

    return this.http.get(`${environment.api}api/orders/cart`, requestOptions);
}

  checkTaxes(isQuote: string): Observable<any> {
    const searchParams: any = new Object();
    searchParams['isQuote'] = isQuote;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers, params: searchParams };
    return this.http.get(`${environment.api}api/orders/checktaxes`, requestOptions);
  }

  checkAvailablePulls(isQuote: string): Observable<any> {
    const searchParams: any = new Object();
    searchParams['isQuote'] = isQuote;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers, params: searchParams };
    return this.http.get(`${environment.api}api/orders/checkavailablepulls`, requestOptions);
  }

  public setShoppingCart(apartmentID: number, isQuote: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentID'] = apartmentID.toString();
    searchParams['isQuote'] = isQuote;
    const requestOptions = { headers, params: searchParams };
    return this.http.post(`${environment.api}api/orders/cart`, '', requestOptions);
  }

  public calculateSH(isQuote: string, complexId: number): Observable<any> {
    const searchParams: any = new Object();
    searchParams['isQuote'] = isQuote;
    searchParams['complexId'] = complexId;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers, params: searchParams };
    return this.http.get(`${environment.api}api/orders/calculatesh`, requestOptions);
  }

  public calculateSHTaxes(iapartmentID: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmetID'] = iapartmentID.toString();
    const requestOptions = { headers, params: searchParams };
    return this.http.post(`${environment.api}api/orders/calculateshtaxes`, '', requestOptions);
  }

  deleteShoppingCart(apartmentID: number, isQuote: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentID'] = apartmentID.toString();
    searchParams['isQuote'] = isQuote;
    const requestOptions = { headers, params: searchParams };
    return this.http.delete(`${environment.api}api/orders/cart`, requestOptions);
  }

  reorderSimilar(sourceAptID: number, destinationAptID: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['sourceAptID'] = sourceAptID.toString();
    searchParams['destinationAptID'] = destinationAptID.toString();
    const requestOptions = { headers, params: searchParams };

    return this.http.post(`${environment.api}/api/apartments/ordersimilar`, '', requestOptions);
  }

  reorderSimilarAddingToCartWithoutReview(sourceAptID: number, destinationAptID: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['sourceAptID'] = sourceAptID.toString();
    searchParams['destinationAptID'] = destinationAptID.toString();
    const requestOptions = { headers, params: searchParams };

    return this.http.post(`${environment.api}/api/apartments/reorderSimilarAddingToCartWithoutReview`, '', requestOptions);
  }

  deleteShoppingCarts(apartmentIds: number[], isQuote: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['isQuote'] = isQuote;
    const requestOptions = { headers, params: searchParams };
    return this.http.post(`${environment.api}api/orders/cart/deleteunits`, apartmentIds, requestOptions);
  }

  submitShoppingCart(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api}/api/orders/syncorder`, '', { headers });
  }
  public updateOrder(apartmentID: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentID'] = apartmentID.toString();
    const requestOptions = { headers, params: searchParams };
    return this.http.put(`${environment.api}api/orders`, '', requestOptions);
  }

  public updateOrderAddressComplex(apartmentID: number, addressId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentID'] = apartmentID.toString();
    searchParams['addressId'] = addressId;
    const requestOptions = { headers, params: searchParams };
    return this.http.put(`${environment.api}api/orders/address`, '', requestOptions);
  }

  public updateOrderAddressByComplex(apartemntComplexId: number, addressId: number, isQuote: string, apartmentId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentComplexId'] = apartemntComplexId.toString();
    searchParams['addressId'] = addressId;
    searchParams['isQuote'] = isQuote;
    searchParams['apartmentId'] = apartmentId;
    const requestOptions = { headers, params: searchParams };
    return this.http.put(`${environment.api}api/orders/addressbycomplex`, '', requestOptions);
  }

  public addUnitsCart(request: number[], phoneNumber: string, cpuEmailAddress: string = ""): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['phoneNumber'] = phoneNumber.toString();
    searchParams['cpuEmailAddress'] = cpuEmailAddress;
    const requestOptions = { headers, params: searchParams };
    return this.http.post(`${environment.api}api/orders/addUnits`, request, requestOptions);
  }

  public UpdateOrderNewAddress(newAddress: NewAddress): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/orders/newaddress`, newAddress, { headers });
  }

  getOrdersByApartmentId(apartmentID: number): Observable<any> {
    const searchParams: any = new Object();
    searchParams['apartmentID'] = apartmentID;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers, params: searchParams };
    return this.http.get(`${environment.api}api/orders/orders`, requestOptions);
  }

  setDeliveryTimes(request: DeliveryEstimationRequest[]): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api}api/orders/addOrderEstimations`, request, { headers });
  }

  runCreditCardPaymentTransaction(creditCardDTO: CreditCard) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api}api/orders/creditcardpayment`, creditCardDTO, { headers });
  }
  getPalletizeOptions(): Observable<any> {
    // tslint:disable-next-line:prefer-const
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = { headers };
    return this.http.get(`${environment.api}api/orders/palletizeoptionsbycustomer`, requestOptiosn);
  }

  applyPromoCode(apartmentID: any, promoCode: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    promoCode = promoCode != null ? promoCode : '';
    let reqParams: any = new Object();
    reqParams['apartmentId'] = apartmentID;
    reqParams['couponCode'] = promoCode;
    const requestOptions = { headers, params: reqParams };
    return this.http.post(`${environment.api}api/orders/ApplyCoupon`, '', requestOptions);
  }

  removePromoCode(apartmentID: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let reqParams: any = new Object();
    reqParams['apartmentId'] = apartmentID;
    const requestOptions = { headers, params: reqParams };
    return this.http.post(`${environment.api}api/orders/RemoveCoupon`, '', requestOptions);
  }

  getProductionDates(): Observable<any> {
    // tslint:disable-next-line:prefer-const
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = { headers };
    return this.http.get(`${environment.api}api/orders/productionDates`, requestOptiosn);
  }

  DisplayUpgradeHinges(): Observable<any> {
    // tslint:disable-next-line:prefer-const
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = { headers };
    return this.http.get(`${environment.api}api/orders/displayupgradehinges`, requestOptiosn);
  }

  DisplayUpgradeLocation(): Observable<any> {
    // tslint:disable-next-line:prefer-const
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = { headers };
    return this.http.get(`${environment.api}api/orders/displayupgradelocation`, requestOptiosn);
  }

  GetAmountHingeUpgrade(): Observable<any> {
    // tslint:disable-next-line:prefer-const
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = { headers };
    return this.http.get(`${environment.api}api/orders/getamounthingeupgrade`, requestOptiosn);
  }

  SetSoftCloseHinges(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api}api/orders/setsoftclosehinges`, { headers });
  }

  SaveHingeUpgradeShoppingCart(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api}api/orders/savehingeupgradeshoppingcart`, { headers });
  }


  SetSoftCloseHingesLocation(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(`${environment.api}api/complexes/updatehingeslocation`, { headers });
  }

  NoUpdateSoftCloseHingesLocation(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(`${environment.api}api/complexes/noupdatehingeslocation`, { headers });
  }

  savePalletForm(palletForm: any, addressId: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let searchParams: any = new Object();
    searchParams['addressId'] = addressId;
    const requestOptions = { headers, params: searchParams };

    return this.http.post(`${environment.api}api/orders/savepalletize`, palletForm, requestOptions);
  }

  editPalletized(palletForm: any, addressId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['addressId'] = addressId;
    const requestOptions = { headers, params: searchParams };
    return this.http.put(`${environment.api}api/orders/editpalletize`, palletForm, requestOptions);
  }

  turnOffPaletizeOrder(addressId: number): Observable<any> {
    const searchParams: any = new Object();
    searchParams['addressId'] = addressId;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptions = { headers, params: searchParams };
    return this.http.post(`${environment.api}api/orders/turnoffpalletizeorder`, {}, requestOptions);
  }

  getPalletizeInformation(palletFormId: number): Observable<any> {
    let searchParams: any = new Object();
    searchParams['palletFormId'] = palletFormId;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const requestOptions = { headers, params: searchParams };
    return this.http.get(`${environment.api}api/orders/getPalletizeInformation`, requestOptions);
  }

  resetPalletFormInOrders(addressId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['addressId'] = addressId;
    const requestOptions = { headers, params: searchParams };
    return this.http.put(`${environment.api}api/orders/resetPalletFormInOrders`, {}, requestOptions);
  }

  removeOvernight(addressId: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/orders/unmarkAsOvernight?addressId=${addressId}`, {}, {headers});
  }

  setOvernightRates(addressId: number, shippingCostApartment: { rate: number, orderId: number }[]): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/orders/SetOvernightRates?addressId=${addressId}`, shippingCostApartment, {headers});
  }

  getSurcharge(): Observable<number> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = {headers};
    return this.http.get<number>(`${environment.api}api/orders/surcharge`, requestOptions);
  }

  getOvernightRates (addressId: number): Observable<ShippingCost> {
    const searchParams: any = new Object();
    searchParams['addressId'] = addressId;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = {headers, params: searchParams};
    return this.http.get<ShippingCost>(`${environment.api}api/orders/OvernightRates`, requestOptions);
  }

  getOvernightEnabled (): Observable<boolean> {
    // tslint:disable-next-line:prefer-const
   const headers = new HttpHeaders();
   headers.append('Content-Type', 'application/json');
   const requestOptiosn = {headers};
    return this.http.get<boolean>(`${environment.api}api/orders/overnightEnabled`, requestOptiosn);
  }

  writeExpeditedOptionSelections(updateLogs: ApartmentUpdateRequest | any): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/apartments/writeExpeditedSelections`, updateLogs);
  }
}
