import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { catchError, tap, timeout } from 'rxjs/operators'
import { ApartmentsNumbersRequest, DeliveryDatesResponse, ExpeditedProductionCapacityRequest, ExpeditedProductionCapacityResponse, ScheduledEstimationRequest } from '../types/shopping-cart';
import { OrderEditionAvailabilityRequest } from "../types/scheduling-types";

@Injectable()
export class SchedulingService{
    constructor(private http: HttpClient) { }

    getSchedulingTentativeDates(body: ScheduledEstimationRequest): Observable<any>{
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.post(`${environment.schedulingAPI}/Schedule/ScheduleEstimation`, body, {headers:headers});
    }

    isOrderEditable(body: OrderEditionAvailabilityRequest): Observable<any>{
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.put(`${environment.schedulingAPI}/Schedule/RequestToEdit`, body, {headers:headers});
    }

    getDeliveryDateByApartments(body: ApartmentsNumbersRequest[]): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        return this.http.post(`${environment.schedulingAPI}/Schedule/DeliveryDateByApartments`, body, {headers});
      }

      getExpeditedProductionCapacity(request: ExpeditedProductionCapacityRequest): Observable<ExpeditedProductionCapacityResponse> {
        return this.http.post<ExpeditedProductionCapacityResponse>(
          `${environment.schedulingAPI}/Production/GetExpeditedProductionCapacity`,
          request,
          {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            })
          }
        ).pipe(
          tap(res => {

          })
        );
      }

      getExpeditedItemNumber(): Observable<string[]> {
        return this.http.get<string[]>(
            `${environment.schedulingAPI}/Production/GetExpeditedItemNumbers`,
            {
                headers: new HttpHeaders({
                    'Accept': 'application/json'
                })
            }
        ).pipe(
            tap(res => {
            })
        );
    }

}