import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Angular Material
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

// Services
import { ApartmentComplexInformationService } from 'app/shared/services/aparment-complex-information.service';
import { FedexService } from 'app/shared/services/fedex.service';
import { ServerStatusService } from 'app/shared/services/server-status.service';
import { ShoppingCartService } from 'app/shared/services/shopping-cart.service';

// Values
import { ShippingAddressPlaceholder, PalletizeContactPlaceholder } from 'app/shared/mock/shipping-address-values';

// Types
import { States } from 'app/shared/types/aparment-complex-info';
import { Fedex } from 'app/shared/types/fedex';
import { PalletizeConfig, PalletizeModalResponse } from 'app/shared/types/order-shopping-cart';
import { PalletizeContact, PalletizeContactForm, ShippingAddressForm } from 'app/shared/types/shipping-address';
import { ShoppingCartExtended } from 'app/shared/types/shopping-cart';

// Components
import { SpinnerComponent } from 'app/spinner/spinner.component';

// Moment
import moment from 'moment';

// BlockUI
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-palletize-modal',
  templateUrl: './palletize-modal.component.html',
  styleUrls: ['./palletize-modal.component.scss']
})
export class PalletizeModalComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @ViewChild('fedexSpinner') public fedexSpinner: SpinnerComponent;
  @ViewChild('fedexModal') public fedexModal: TemplateRef<any>;

  public palletize_form: FormGroup;
  public palletizeInformation: PalletizeContactForm = this.initPalletForm();
  public isAlreadyPalletized: boolean = false;
  public palletizedAddress = new ShippingAddressForm(null, null, '', 'Leasing Office', null, null, null, '', 'State*', null);
  public states: States[] = [];
  public valid = false;
  public optionSelect: Array<boolean>;
  public shippingAddressPlaceholder = ShippingAddressPlaceholder;
  public palletizeContactPlaceholder = PalletizeContactPlaceholder;
  public fedexMatcher: any = new Fedex(['', ''], '', '', '', '');
  public match: string;
  public keepAddress: boolean;
  public badAddressMessage: string;
  public palletForm: any;
  public minDeliveryDate: Date;

  date = new FormControl(moment());
  missingDates: Date[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private shoppingCartService: ShoppingCartService,
    private apartmentComplexInformationService: ApartmentComplexInformationService,
    private fedexService: FedexService,
    private serverStatusService: ServerStatusService,
    public dialogRef: MatDialogRef<PalletizeModalComponent, PalletizeModalResponse>,
    public dialog: MatDialog,
    // @Inject(MAT_DIALOG_DATA) public data: { shoppingCart: ShoppingCartExtended, palletizeOptions: any[], productionDates: { start: string }[] }
    @Inject(MAT_DIALOG_DATA) public data: PalletizeConfig
  ) { }

  async ngOnInit() {
    this.generateMissingDates(this.data.productionDates.map(x => {
      let offsetMinutes = new Date().getTimezoneOffset();
      let localTime = new Date(x.start);
      return new Date(localTime.getTime() + offsetMinutes * 60000);
    }));
    const re_email = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,12}|[0-9]{1,3})(\]?)$/;

    this.minDeliveryDate = this.calculateMinDeliveryDate();
    this.palletize_form = this.formBuilder.group({
      'name': [null],
      'addressLine1': [null, Validators.required],
      'addressLine2': [null],
      'addressLine3': ['Leasing Office'],
      'zipCode': [null, Validators.required],
      'city': [null, Validators.required],
      'state': [null, Validators.required],
      'phone': [null],
      'contactPhone': [null],
      'shippingInstructions': [null],
      'contactName': [null, Validators.required],
      'contactEmail': new FormControl('', [Validators.required, Validators.pattern(re_email)]),
      'authorizationcontactEmail': new FormControl('', [Validators.required, Validators.pattern(re_email)]),
      'entranceCode': [null],
      'aditionalInformation': [null],
      'authorizationContactName': [null, Validators.required],
      'authorizationContactPhone': [null],
    });

    this.optionSelect = new Array(this.data.palletizeOptions.length).fill(false);
    await this.isPalletizedOrder();

    await this.getStates();
    await this.setPalletizeAddress();

    if (!this.isAlreadyPalletized) {
      this.optionSelect[0] = true;
      this.palletizeInformation.palletizeOptionID = this.data.palletizeOptions[0].palletizeOptionId;
    } else {
      await this.getPalletizeInformation();
    }

    setTimeout(() => {
      this.validateForm();
    }, 100);
  }

  scrollToField(dateField: HTMLInputElement) {
    if (dateField) {
      dateField.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  }

  initPalletForm(): PalletizeContactForm {
    return new PalletizeContactForm(
      '', '', '', '', '', '', 0,
      '', '', '', '', '', '',
      '', false, false, false, false, 0, '', false, 0, PalletizeContact[2] = [{ id: 0, fullName: "", phoneNumber: "", emailAddres: "", contactTypeId: 1 }, { id: 0, fullName: "", phoneNumber: "", emailAddres: "", contactTypeId: 2 }]);
  }

  closeModal() {
    this.dialogRef.close({ state: false });
  }

  calculateMinDeliveryDate(): Date {
    const today = new Date();
    let businessDays = 9;

    while (businessDays > 0) {
      today.setDate(today.getDate() + 1);

      if (today.getDay() !== 0 && today.getDay() !== 6) {
        businessDays--;
      }
    }

    return today;
  }

  getMinMaxDates(dates: Date[]): { minDate: Date | null, maxDate: Date | null } {
    if (!dates || dates.length === 0) {
      return { minDate: null, maxDate: null };
    }

    let minDate = dates[0];
    let maxDate = dates[0];

    for (const date of dates) {
      if (date < minDate) {
        minDate = date;
      }
      if (date > maxDate) {
        maxDate = date;
      }
    }

    return { minDate, maxDate };
  }

  addMissingDates(arr1: Date[], arr2: Date[]): Date[] {
    const missingDates: Date[] = [];

    for (const date of arr1) {
      if (!this.isInArray(arr2, date.toJSON())) {
        let offsetMinutes = new Date().getTimezoneOffset();
        let localTime = new Date(date);

        missingDates.push(new Date(localTime.getTime() + offsetMinutes * 60000));
      }
    }

    return missingDates;
  }

  generateMissingDates(productionDates: Date[]) {
    const { minDate, maxDate } = this.getMinMaxDates(productionDates);
    const datesInRange = this.getDatesInRange(minDate, maxDate);
    this.missingDates = this.addMissingDates(datesInRange, productionDates);
  }

  isInArray = (array: Date[], value: string) => array.some(item => this.isSameDate(item, moment.utc(value).toDate()));

  getDatesInRange(startDate: Date, endDate: Date): Date[] {
    const datesInRange: Date[] = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      datesInRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesInRange;
  }

  datesFilter = (d: Date): boolean => {
    if (this.missingDates.some(excludedDate => this.isSameDate(d, excludedDate))) {
      return false;
    }

    return d.getDay() !== 0 && d.getDay() !== 6;
  }

  private isSameDate(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  async editPalletized() {
    let palletId = await this.shoppingCartService.editPalletized(this.palletizeInformation, this.data.shoppingCartGroup.addressId).toPromise();
    this.palletizeInformation = this.initPalletForm();
    this.blockUi.stop();

    this.dialogRef.close({ state: true, palletizeFormId: palletId});
  }

  async savePalletized() {
    let palletId = await this.shoppingCartService.savePalletForm(this.palletizeInformation, this.data.shoppingCartGroup.addressId).toPromise();
    this.isAlreadyPalletized = palletId == 0 ? false : true;
    this.palletizeInformation = this.initPalletForm();
    this.blockUi.stop();

    this.dialogRef.close({ state: true, palletizeFormId: palletId});
  }

  validateForm() {
    this.valid =
      this.palletize_form.valid
      && this.palletizedAddress.shippingAddressState !== 'State*'
      && this.palletizeInformation.palletizeOptionID > 0
      && this.palletizeInformation.deliveryDate != '';
  }

  setState(state: string) {
    this.palletizedAddress.shippingAddressState = state;
    if (this.states && (<any[]>this.states).length > 0) {
      for (const states of this.states) {
        if (states.name === state) {
          this.palletizeInformation.shippingAddressStateId = states.id;
          break;
        }
      }
    }
  }

  setStateName(stateId: number) {
    for (const states of this.states) {
      if (states.id == stateId) {
        this.palletizeInformation.shippingAddressState = states.name;
        break;
      }
    }
  }

  async getStates() {
    this.states = await this.apartmentComplexInformationService.getStates().toPromise();
  }

  async getPalletizeInformation() {
    if (this.isAlreadyPalletized) {
      this.palletizeInformation = await this.shoppingCartService.getPalletizeInformation(this.data.palletizeFormId).toPromise();
      this.optionSelect[this.palletizeInformation.palletizeOptionID - 1] = true;
      this.setStateName(this.palletizeInformation.shippingAddressStateId);
    }
  }

  choseKitOptions(index: number, palletizeOptionId: number): void {
    this.optionSelect.fill(false);
    this.optionSelect[index] = true;
    this.palletizeInformation.palletizeOptionID = palletizeOptionId;
    this.validateForm()
  }

  async setPalletizeAddress() {
    var address = this.data.shoppingCartGroup;
    this.palletizeInformation.shippingAddressName = address.addressName;
    this.palletizeInformation.shippingAddressLine1 = address.addressLine1;
    this.palletizeInformation.shippingAddressCity = address.city;
    this.palletizeInformation.shippingAddressZipCode = address.zipCode;
    this.palletizeInformation.shippingAddressStateId = this.states.find(f => f.abbreviation === address.state).id;
    this.palletizeInformation.shippingAddressLine2 = address.addressLine2;
    for (const states of this.states) {
      if (states.id === this.palletizeInformation.shippingAddressStateId) {
        this.palletizeInformation.shippingAddressState = states.name;
        break;
      }
    }
  }

  async isPalletizedOrder() {
    this.isAlreadyPalletized = this.data.palletizeFormId !== 0 && this.data.palletizeFormId != null;
  }

  async submitForm(): Promise<void> {
    this.modalFedex();
  }

  async modalFedex() {
    if (this.palletize_form.valid) {
      const originalAddress: Fedex = {
        streetLines: [this.palletizeInformation.shippingAddressLine1, this.palletizeInformation.shippingAddressLine2],
        postalCode: this.palletizeInformation.shippingAddressZipCode,
        city: this.palletizeInformation.shippingAddressCity,
        stateOrProvince: this.palletizedAddress.shippingAddressState,
        countryCode: 'US'
      };
      this.match = '';
      this.badAddressMessage = '';

      this.dialog.open(this.fedexModal, { height: "530px", maxWidth: "900px", id: "fedex-modal" });
      this.blockUi.start('Loading matches...');
      try {
        this.fedexMatcher = <any>await this.fedexService.getAddress(originalAddress).toPromise();
        if (this.fedexMatcher && this.fedexMatcher.warningMessage) {
          this.badAddressMessage = this.fedexMatcher.warningMessage;
        }
        if (this.fedexMatcher.length === 0) {
          this.fedexSpinner.onSuccess('Not Matches found...');
          this.blockUi.stop();
        } else {
          this.match = this.fedexMatcher.streetLines.length > 1 ?
            `${this.fedexMatcher.streetLines[0]},
          ${this.fedexMatcher.streetLines[1]},
          ${this.fedexMatcher.city},
          ${this.fedexMatcher.stateOrProvince},
          ${this.fedexMatcher.postalCode}` :
            `${this.fedexMatcher.streetLines[0]},
          ${this.fedexMatcher.city},
          ${this.fedexMatcher.stateOrProvince},
          ${this.fedexMatcher.postalCode}`;
          this.blockUi.stop();
          this.fedexSpinner.onSuccess('Matches loaded...');
        }

      } catch (err) {
        this.blockUi.stop();
        let error = this.serverStatusService.status(err.status, '');
        if (!error) {
          error = `${err.status} - ${err.statusText}`;
        }
        this.fedexSpinner.onFail(error);
      }
    }
  }

  setFedex(keep: boolean) {
    this.blockUi.start('Saving');

    if (keep) {
      this.dialog.getDialogById('fedex-modal').close();
      this.fedexMatcher = new Fedex(['', ''], '', '', '', '');
      this.match = '';
      this.keepAddress = undefined;
      this.apartmentComplexInformationService.sectionHandler.next(8);
    } else {
      this.palletizedAddress.shippingAddressLine1 = this.fedexMatcher.streetLines[0];
      this.palletizedAddress.shippingAddressZipCode = this.fedexMatcher.postalCode;
      this.palletizedAddress.shippingAddressCity = this.fedexMatcher.city;
      for (const state of this.states) {
        if (this.fedexMatcher.stateOrProvince.toUpperCase() === state.abbreviation.toUpperCase()) {
          this.palletizedAddress.shippingAddressState =  String(state.id);
          break;
        }
      }
      this.fedexMatcher = new Fedex(['', ''], '', '', '', '');
      this.match = '';
      this.keepAddress = undefined;
      this.dialog.getDialogById('fedex-modal').close();
    }
    if (this.palletizeInformation.palletFormId > 0 && this.data.shoppingCartGroup.hasSomeWithPalletized)
      this.editPalletized();
    else
      this.savePalletized();
  }

  phone(phone: string, model: string, event: KeyboardEvent): void {
    if (event.key !== 'Backspace') {
      if (phone.length === 3 || phone.length === 7) {
        if (event.key !== '-') {
          if (model == "1") {
            this.palletizeInformation.shippingAddressPhone = phone += '-';
          }
          else if (model == "2") {
            this.palletizeInformation.contactList[0].phoneNumber = phone += '-';
          }
          else {
            this.palletizeInformation.contactList[1].phoneNumber = phone += '-';
          }
        }
      }
    }
  }
}
