import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { LoginService } from '../shared/services/login.service';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router, public toastr: ToastrService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    this.loginService.getAccount().subscribe( () => {});
    if (this.loginService.authenticate && this.loginService.getToken() && !this.loginService.getTokenQwikkitOrderingIsExpired()) {
      return true;
    }
    this.toastr.error('please re-sign in', 'Your session has expired',
      {
        positionClass: 'toast-bottom-left',
        easeTime: 3000,
        progressAnimation: 'increasing'
      }).onShown.subscribe(() => {
        this.loginService.redirectUrl = url;
        this.router.navigate(['/login']);
    });
    return false;
  }
}
@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    return true;
  }

  checkLogin(url: string): boolean {
    if (this.loginService.authenticate && this.loginService.getToken() && !this.loginService.getTokenQwikkitOrderingIsExpired()) {
      this.loginService.redirectUrl = url;
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
