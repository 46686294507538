import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-qr-modal',
  templateUrl: './qr-modal.component.html',
  styleUrls: ['./qr-modal.component.scss']
})
export class QrModalComponent implements OnInit {
  shortUrl: string | null = null;
  qrCodeImage: string | null = null;
  logoPath: string = 'assets/images/qwikkit-logo.png'; 
  
  @ViewChild('qrCanvas') qrCanvas!: ElementRef<HTMLCanvasElement>;

  constructor(
    private dialogRef: MatDialogRef<QrModalComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.generateQrCode();
  }

  generateQrCode(): void {
    const orderId = 234325 //this.data.orderId;
    if (!orderId) {
      console.error("Order ID is missing.");
      return;
    }

    this.http.get<{ shortUrl: string, qrCodeBase64: string }>(
      `${environment.apiQwikkitOrdering}api/qrcode/generate?orderId=${orderId}`
    ).subscribe(response => {
        this.shortUrl = response.shortUrl;
        this.qrCodeImage = response.qrCodeBase64;
        
        setTimeout(() => {
          this.overlayLogo();
        }, 100); // ✅ Wait for the QR code to render before overlaying the logo
      },
      error => console.error('Error generating QR code:', error)
    );
  }

  overlayLogo(): void {
    if (!this.qrCodeImage || !this.qrCanvas) return;
  
    const canvas = this.qrCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
  
    if (!ctx) return;
  
    const qrImg = new Image();
    qrImg.src = this.qrCodeImage;
    qrImg.crossOrigin = "Anonymous"; 
    qrImg.onload = () => {
      canvas.width = qrImg.width;
      canvas.height = qrImg.height;

      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
  
      ctx.drawImage(qrImg, 0, 0, canvas.width, canvas.height);
  
      const logoImg = new Image();
      logoImg.src = this.logoPath;
      logoImg.crossOrigin = "Anonymous"; 
      logoImg.onload = () => {
        const logoSize = canvas.width / 5; 
        const x = (canvas.width - logoSize) / 2;
        const y = (canvas.height - logoSize) / 2;

        const tempCanvas = document.createElement("canvas");
        const tempCtx = tempCanvas.getContext("2d");
  
        if (!tempCtx) return;
  
        tempCanvas.width = logoSize;
        tempCanvas.height = logoSize;
  
        tempCtx.fillStyle = "white";
        tempCtx.fillRect(0, 0, logoSize, logoSize);
  
        tempCtx.drawImage(logoImg, 0, 0, logoSize, logoSize);

        ctx.drawImage(tempCanvas, x, y, logoSize, logoSize);
      };
    };
  }
  
  

  close(): void {
    this.dialogRef.close();
  }
}
