import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./guards/auth.guard";

const APP_ROUTES: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "dashboard",
    loadChildren: "app/dashboard/dashboard.module#DashboardModule",
  },
  {
    path: "apartment-complex/:id",
    loadChildren:
      "app/apartment-complex-information/apartment-complex-information.module#ApartmentComplexInformationModule",
  },
  {
    path: "apartment-complex/:id/:section",
    loadChildren: "app/apartment-complex-information/apartment-complex-information.module#ApartmentComplexInformationModule",
  },
  {
    path: "location/:id",
    loadChildren: "app/location/location.module#LocationModule",
  },
  {
    path: "location-setup/new",
    loadChildren: "app/location-setup/location-setup.module#LocationSetupModule",
  },
  {
    path: "apartment-complex/:complexID",
    loadChildren: "app/new-order/new-order.module#NewOrderModule",
  },
  {
    path: "apartment-complex/:complexID/order-review",
    loadChildren: "app/order-review/order-review.module#OrderReviewModule",
  },
  {
    path: "account1234",
    loadChildren: "app/account-creation/account-creation.module#AccountCreationModule",
  },
  {
    path: "wizard",
    loadChildren: "app/guidelines-wizard/guidelines-wizard.module#GuidelinesWizardModule",
  },
  {
    path: "wizard-es",
    loadChildren: "app/guidelines-wizard-spanish/guidelines-wizard-es.module#GuidelinesWizardEsModule",
  },
  {
    path: "resend-orders",
    loadChildren: "app/resend-orders/resend-orders.module#ResendOrdersModule",
  },
  {
    path: "resend-customer",
    loadChildren: "app/resend-customer/resend-customer.module#ResendCustomerModule",
  },
  // {
  //   path: "shopping-cart",
  //   loadChildren: "app/shopping-cart/shopping-cart.module#ShoppingCartModule",
  // },
  // {
  //   path: "shopping-cart/:isQuote",
  //   loadChildren: "app/shopping-cart/shopping-cart.module#ShoppingCartModule",
  // },
  {
    path: "order-shopping-cart",
    loadChildren: "app/order-shopping-cart/order-shopping-cart.module#OrderShoppingCartModule",
  },
  {
    path: "order-shopping-cart/:isQuote",
    loadChildren: "app/order-shopping-cart/order-shopping-cart.module#OrderShoppingCartModule",
  },
  {
    path: "shopping-cart/modalv2",
    loadChildren: "app/shopping-cart/shopping-cart.module#ShoppingCartModule",
  },
  {
    path: "statements",
    loadChildren: "app/statements/statement.download.module#StatementDownloadModule",
  },
  { path: "**", redirectTo: "/dashboard" },
];

export const routing = RouterModule.forRoot(APP_ROUTES);
